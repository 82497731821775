import * as Sentry from "@sentry/react";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ICustomer, IUser } from "api/user";
import DashboardLayout from 'components/DashboardLayout';
import ErrorPage from "components/ErrorPage";
import MarginalPage from "components/MarginalPage";
import ProjectionsPage from "components/ProjectionsPage";
import RealtimePage from "components/RealtimePage";
import { receiveCustomerAndUser } from "modules/app/slice";
import { AppDispatch } from "modules/store";


interface IAppProps {}


interface IAppState {
  hasError: boolean
}


class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error): void {
    Sentry.captureException(error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage/>;
    }
    return <BrowserRouter>
      <Routes>
        <Route path="/" index element={<DashboardLayout/>} />
        <Route path="/realtime" index element={<RealtimePage/>} />
        <Route path="/marginal" index element={<MarginalPage />} />
        <Route path="/projections" index element={<ProjectionsPage />} />
      </Routes>
    </BrowserRouter>;
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  receiveUserAndCustomer: (res: {user: IUser | null, customer: null | ICustomer}) => dispatch(receiveCustomerAndUser(res)),
});

export default connect(null, mapDispatchToProps)(App);