import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';
import { NavLink } from 'react-router-dom';

import './style.css';

const Navbar = () => {
  return (
    <Stack
      direction="row"
      className="navbar-stack"
      justifyContent='space-between'
      alignContent='center'
      alignItems='center'
      p={1}>
      <a href="https://www.misoenergy.org/" target="_blank">
        <img src='https://singularity-public-assets.s3.amazonaws.com/miso/misoLogoWhite.svg'
            alt="" width="100" className="d-inline-block align-text-top"/>
      </a>
      <Box className="navbar-link--container tour--new-navbar">
        <NavLink to="/" className="navbar--link">Historical</NavLink>
        <NavLink to="/realtime" className="navbar--link">Near Real-time</NavLink>
        <NavLink to="/marginal" className="navbar--link">Marginal</NavLink>
        <NavLink to="/projections" className="navbar--link">Projections</NavLink>
      </Box>
      <a href="https://singularity.energy" target="_blank">
        <img src='https://singularity-public-assets.s3.amazonaws.com/logo/powered_by_3_white.svg'
              alt="" width="100" className="d-inline-block align-text-top"/>
      </a>
    </Stack>
  );
}

export default Navbar;
